import { Address, Coding } from "fhir"

import { FormatTypes } from "commons"

const PRACTITIONER_DS_IDENTIFIER_SYSTEM = "http://dosespot.com/practitioner/identifier"
const LAB_REFERENCE_ID_CODE = "ACSN"

const formatsByTypes: { [key in FormatTypes]: string } = {
  ISO_8601_DATE: "yyyy-MM-dd",
  ISO_8601_DATETIME: "yyyy-MM-dd'T'HH:mm:ss",
  SHORT_DATE: "MMM do",
  SHORT_DATETIME: "MMM do, h:mm a",
  SHORT_DATETIME_W_SEC: "MMM do, h:mm:ss a",
  LONG_DATE: "MMM do, yyyy",
  LONG_DATETIME: "MMM do, yyyy h:mm a",
  FULL_DATETIME: "MMMM do, yyyy h:mm a",
  TIME: "h:mm a",
  SHORT_MONTH_YEAR: "MMM yyyy",
  LONG_MONTH_YEAR: "MMMM yyyy",
  LONG_DATETIME_WITH_TIMEZONE: "MMM do, yyyy h:mm a z",
  SHORT_DATETIME_WITH_TIMEZONE: "MMM do, h:mm a z",
  FULL_DATETIME_WITH_TIMEZONE: "MMMM do, yyyy h:mm a z",
}

const nonContinentalStates = ["VI", "AE", "AP", "AS", "GU", "HI", "MH", "MP", "PR", "PW", "UM"]

const medicationRequestStatus = [
  { code: "on-hold", display: "Scheduled" },
  { code: "stopped", display: "Stopped" },
  { code: "completed", display: "Completed" },
  { code: "active", display: "Active" },
]

enum ContactPointSystem {
  phone = "phone",
  fax = "fax",
  email = "email",
  pager = "pager",
  url = "URL",
  sms = "SMS",
  other = "other",
}

enum ProfileSection {
  BASIC = "basic_information",
  CONTACT = "contact_information",
  ADDRESS = "address_information",
  CC = "credit_cards",
  INSURANCE = "insurance_information",
  EMERGENCY_CONTACT = "emergency_contacts",
  PATIENT_DOCUMENTS = "patient_documents",
}

const genders = [
  { code: "female", label: "Female" },
  { code: "male", label: "Male" },
]

const sexCodes = [
  { code: "female", display: "Female" },
  { code: "male", display: "Male" },
]

const contactRelationship = [
  { code: "C", display: "Emergency Contact" },
  { code: "E", display: "Employer" },
  { code: "F", display: "Federal Agency" },
  { code: "I", display: "Insurance Company" },
  { code: "N", display: "Next-of-Kin" },
  { code: "S", display: "State Agency" },
  { code: "U", display: "Unknown" },
]

const countries = [{ label: "United States", code: "US" }]

const vitalSignCodes = {
  weight: {
    coding: [
      {
        system: "http://loinc.org",
        code: "29463-7",
        display: "Body Weight",
      },
    ],
    text: "Body Weight",
  },
  height: {
    coding: [
      {
        system: "http://loinc.org",
        code: "8302-2",
        display: "Body height",
      },
    ],
    text: "Body height",
  },
  heartRate: {
    coding: [
      {
        system: "http://loinc.org",
        code: "8867-4",
        display: "Heart rate",
      },
    ],
    text: "Heart rate",
  },
  temperature: {
    coding: [
      {
        system: "http://loinc.org",
        code: "8310-5",
        display: "Body temperature",
      },
    ],
    text: "Body temperature",
  },
  respiratoryRate: {
    coding: [
      {
        system: "http://loinc.org",
        code: "9279-1",
        display: "Respiratory rate",
      },
    ],
    text: "Respiratory rate",
  },
  oxygenSaturation: {
    coding: [
      {
        system: "http://loinc.org",
        code: "59408-5",
        display: "Oxygen saturation in Arterial blood by Pulse oximetry",
      },
    ],
    text: "Oxygen saturation in Arterial blood by Pulse oximetry",
  },
  bloodPressure: {
    coding: [
      {
        system: "http://loinc.org",
        code: "55284-4",
        display: "Blood Pressure",
      },
    ],
    text: "Blood Pressure",
  },
}

const invoiceStatusCodes = [
  { code: "draft", display: "Draft" },
  { code: "issued", display: "Issued" },
  { code: "balanced", display: "Paid" },
  { code: "cancelled", display: "Cancelled" },
]

const systems: Record<"invoice" | "shippingMethod" | "shippingMethodDefault" | "fee", string> = {
  invoice: "http://chartedhealth.com/fhir/payment/invoice-identifier",
  shippingMethod: "http://chartedhealth.com/fhir/shipping-method",
  shippingMethodDefault: "http://chartedhealth.com/fhir/shipping-method-default",
  fee: "http://chartedhealth.com/fhir/payment/fee",
}
const taskCodes = [
  { system: "http://chartedhealth.com/fhir/task-code", code: "organizational", display: "Organizational" },
]

const taskPriorityCodes = [
  {
    code: "routine",
    display: "Routine",
  },
  {
    code: "urgent",
    display: "Urgent",
  },
  {
    code: "asap",
    display: "ASAP",
  },
  {
    code: "stat",
    display: "STAT",
  },
]

const medicationRequestOrdersStatus = [
  { code: "revoked", display: "Cancelled" },
  { code: "completed", display: "Completed" },
  { code: "active", display: "Active" },
]

const laboratoryOrderStatusCodes = [
  { code: "on-hold", display: "On Hold" },
  { code: "requisition-pending", display: "Requisition Pending" },
  { code: "requisition-available", display: "Requisition Available" },
  { code: "preliminary-results", display: "Preliminary Results" },
  { code: "final-results-available", display: "Final Results Available" },
  { code: "revoked", display: "Cancelled" },
]

const unitOfTime = [
  { code: "s", display: "Second", system: "http://unitsofmeasure.org" },
  { code: "min", display: "Minute", system: "http://unitsofmeasure.org" },
  { code: "h", display: "Hour", system: "http://unitsofmeasure.org" },
  { code: "d", display: "Day", system: "http://unitsofmeasure.org" },
  { code: "wk", display: "Week", system: "http://unitsofmeasure.org" },
  { code: "mo", display: "Month", system: "http://unitsofmeasure.org" },
  { code: "a", display: "Year", system: "http://unitsofmeasure.org" },
]

const creditCardTypes = [
  { code: "AE", label: "American Express", system: "http://terminology.hl7.org/CodeSystem/v3-ActCode" },
  { code: "MC", label: "Master Card", system: "http://terminology.hl7.org/CodeSystem/v3-ActCode" },
  { code: "V", label: "Visa", system: "http://terminology.hl7.org/CodeSystem/v3-ActCode" },
  { code: "D", label: "Discover", system: "http://terminology.hl7.org/CodeSystem/v3-ActCode" },
  { code: "JCB", label: "JCB", system: "http://terminology.hl7.org/CodeSystem/v3-ActCode" },
  { code: "DC", label: "Diners Club", system: "http://terminology.hl7.org/CodeSystem/v3-ActCode" },
]

const cpoeActions: Record<"addFee" | "activateMR" | "deactivateMR", Coding> = {
  addFee: {
    code: "add-fee",
    system: "http://chartedhealth.com/fhir/cpoe/action",
  },
  activateMR: {
    code: "activate",
    system: "http://chartedhealth.com/fhir/cpoe/action",
    display: "Activate",
  },
  deactivateMR: {
    code: "draft",
    system: "http://chartedhealth.com/fhir/cpoe/action",
    display: "Draft",
  },
}

const insuranceRelationship: Record<"self" | "other" | "parent" | "spouse", Coding> = {
  self: {
    code: "self",
    system: "http://terminology.hl7.org/CodeSystem/subscriber-relationship",
    display: "Self",
  },
  spouse: {
    code: "spouse",
    system: "http://terminology.hl7.org/CodeSystem/subscriber-relationship",
    display: "Spouse",
  },
  parent: {
    code: "parent",
    system: "http://terminology.hl7.org/CodeSystem/subscriber-relationship",
    display: "Parent",
  },
  other: {
    code: "other",
    system: "http://terminology.hl7.org/CodeSystem/subscriber-relationship",
    display: "Other",
  },
}

type MrCategoryCodes = "procedure" | "nutraceutical" | "medication" | "write-in" | "refrigerated"

const mrCategoryCodes: Record<MrCategoryCodes, Coding> = {
  procedure: {
    code: "procedure",
    system: "http://chartedhealth.com/fhir/medicationrequest-category",
    display: "Procedure",
  },
  nutraceutical: {
    code: "nutraceutical",
    display: "Nutraceutical",
    system: "http://chartedhealth.com/fhir/medication-request-category",
  },
  medication: {
    code: "medication",
    display: "Medication",
    system: "http://chartedhealth.com/fhir/medication-request-category",
  },
  "write-in": {
    code: "write-in",
    display: "Write in",
    system: "http://chartedhealth.com/fhir/medication-request-category",
  },
  refrigerated: {
    code: "refrigerated",
    system: "http://chartedhealth.com/fhir/medicationrequest-category",
    display: "Refrigerated",
  },
}

const emptyAddress: Address = {
  country: "US",
  line: ["", ""],
  city: "",
  state: "",
  postalCode: "",
  use: "home",
}

const azureContainer = {
  photos: "practitioner-photos",
  drs: "diagnostic-reports",
  branding: "branding-images",
  docs: "document-reference",
}

enum ServiceRequestCategory {
  LAB_ORDER = "lab-order",
  LAB_ORDER_COMBO = "lab-order-combo",
  LAB_ORDER_PANEL = "lab-order-panel",
}

enum ERROR_CAUSE {
  AUTH_RESOURCE_NOT_FOUND = "Auth Resource Not Found",
  RESOURCE_NOT_FOUND = "Resource Not Found",
  UNAUTHORIZED = "Unauthorized",
  INTERNAL_SERVER_ERROR = "Internal Server Error",
  NETWORK_ERROR = "Network Error",
  FORBIDDEN = "Forbidden",
}

export enum BillingTypeCodes {
  BILL_PATIENT = "bill-patient",
  BILL_PRACTICE = "bill-practice",
  INSURANCE = "insurance",
}

const billingTypes = [
  {
    code: BillingTypeCodes.BILL_PATIENT,
    display: "Bill to patient",
    system: "http://chartedhealth.com/fhir/coverage-type",
  },
  {
    code: BillingTypeCodes.BILL_PRACTICE,
    display: "Bill to practice - Credit Card",
    system: "http://chartedhealth.com/fhir/coverage-type",
  },
  {
    code: BillingTypeCodes.INSURANCE,
    display: "3rd Party / Insurance",
    system: "http://chartedhealth.com/fhir/coverage-type",
  },
]

const COMBO_PROMO_CODE = "promo"

const OVERNIGHT_FEE_CODES = ["rush-order", "overnight-shipping"]

export enum MC_ACTIVITY_TYPE {
  MC = "mc",
  MC_SURVEY = "mc-survey",
}

export const MC_PUBLISHER = "MetabolicCode"

export enum MEDICATION_CATALOG {
  RX = "pharmacy",
  NUTRA = "medication-distributor",
}

export enum BILLING_TYPES_CODES {
  BILL_PATIENT = "bill-patient",
  BILL_PRACTICE = "bill-practice",
  INSURANCE = "insurance",
}

export enum MEDICATION_PRODUCT_TYPE {
  RX = "medication",
  NUTRA = "nutraceutical",
}

export enum MED_FEE_TYPE {
  Fixed = "fixed",
  ByFrequency = "by-frequency",
  Normal = "nutra-fee",
}

export const ADULT_SIGNATURE_REQUIRED = "dispensing-adult-signature-required-order"

export const REFRIGERATED = "refrigerated"

export enum UNIT_DAYS {
  MONTH = 28,
  WEEK = 7,
  YEAR = 365,
}

export enum PD_ACTION_DYNAMIC_VALUE {
  REUSE = "reuse",
  NOT_SHOWABLE = "noShowable",
  NOT_EDITABLE = "noEditable",
  DEFAULT_DATE = "defaultDate",
  DAYS_BEFORE_APPOINTEMENT = "daysBeforeAppointment",
  REASON_CODE = "reasonCode",
  RECURSIVE_ENROLL = "recursionUntilDays",
  QUESTION_ID = "questionId",
}

const APPOINTMENT_TYPE_DEFAULT_COLOR = "#3788d8"

export {
  azureContainer,
  APPOINTMENT_TYPE_DEFAULT_COLOR,
  billingTypes,
  COMBO_PROMO_CODE,
  ContactPointSystem,
  contactRelationship,
  countries,
  cpoeActions,
  creditCardTypes,
  emptyAddress,
  ERROR_CAUSE,
  formatsByTypes,
  genders,
  insuranceRelationship,
  invoiceStatusCodes,
  LAB_REFERENCE_ID_CODE,
  laboratoryOrderStatusCodes,
  medicationRequestOrdersStatus,
  medicationRequestStatus,
  mrCategoryCodes,
  nonContinentalStates,
  OVERNIGHT_FEE_CODES,
  PRACTITIONER_DS_IDENTIFIER_SYSTEM,
  ProfileSection,
  ServiceRequestCategory,
  sexCodes,
  systems,
  taskCodes,
  taskPriorityCodes,
  unitOfTime,
  vitalSignCodes,
}
